// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// STACKS CUSTOM
require("jquery")
require("bootstrap")
// require("popper.js")

// require ("typeahead")

import EasyMDE from "easymde.min.js"
import Handlebars from "handlebars.min.js"
import Mousetrap from "mousetrap.min.js"
import Tribute from "tribute.js"
// import * as typeahead from 'typeahead.jquery.js' // THIS IS NOT WORKING
import Bloodhound from "bloodhound.js"
// import Bloodhound from 'bloodhound-js';

// require("imports-loader?typeahead.js/dist/typeahead.jquery.js");
// require("imports-loader?exports=>false,define=>false!typeahead.js/dist/bloodhound.min.js");

global.EasyMDE = EasyMDE;
global.Handlebars = Handlebars;
global.Bloodhound = Bloodhound;
// global.typeahead = typeahead;
global.Tribute = Tribute;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
	 // $('[data-toggle="tooltip"]').tooltip()
	 // $('[data-toggle="popover"]').popover()
 });